import {useState} from 'react';
import { TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, TableSortLabel } from '@mui/material';
import moment from 'moment';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import { getCurrencySymbol } from 'utils/calCommonFunction';
import { Trans, useTranslation } from 'react-i18next';
import { TableStyle, CheckboxStyle, DividerStyle, LightTooltip, InfoIcon } from './styled-components';

const descendingComparator = (a, b, orderBy) => {
    let valA =  a[orderBy];
    let valB = b[orderBy];
    if (orderBy === 'amount') {
        valA = a.amount.amount;
        valB = b.amount.amount;
    }
    if (valB < valA) {
        return -1;
    }
    if (valB > valA) {
        return 1;
    }
    return 0;
}
  
const getComparator = (order, orderBy) => order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)

const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
};

export default function Table({ transactions = [], onTransactionReviewed = () => {}, onRowClick = () => {} }) {
    const currencySymbol = getCurrencySymbol();
    const [order, setOrder] = useState("desc");
    const [orderBy, setOrderBy] = useState("date");

    const handleIsReviewClicked = (e, transactionId, isReviewed) => {
        e.stopPropagation();
        onTransactionReviewed(transactionId, isReviewed);
    };

    const { t } = useTranslation();

    const handleSort = (e, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    return (
        <>
            <TableContainer sx={{ maxHeight: 500 }}>
                <TableStyle stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ width: '12%', padding: 10 }}>
                                <TableSortLabel
                                    active={orderBy === 'date'}
                                    direction={orderBy === 'date' ? order : "asc"}
                                    onClick={(e) => handleSort(e, 'date')}
                                >
                                    {t('downloaded-transactions.date')}
                                    <LightTooltip style={{marginLeft: 3 }} title={
                                        <Typography>{t('downloaded-transactions.Default-setting')}</Typography>
                                    }>
                                        <InfoIcon src='/static/linkFinancial/info-icon.svg' alt="" />
                                    </LightTooltip>
                                </TableSortLabel>
                            </TableCell>
                            <TableCell style={{ width: '18%', padding: 10 }}>{t('downloaded-transactions.Payee/Merchant')}</TableCell>
                            <TableCell style={{ width: '15%', padding: 10 }}>{t('common-translation.Category')}</TableCell>
                            <TableCell style={{ width: '15%', padding: 10 }}>{t('downloaded-transactions.Notes')}</TableCell>
                            <TableCell style={{ padding: 10 }} className='th-foreign-currency'>{t('downloaded-transactions.foreign-currency')}</TableCell>
                            <TableCell style={{ width: '10%', padding: 10 }}>
                                <TableSortLabel
                                    active={orderBy === 'amount'}
                                    direction={orderBy === 'amount' ? order : "asc"}
                                    onClick={(e) => handleSort(e, 'amount')}
                                >
                                    {t('downloaded-transactions.Amount')}
                                    <LightTooltip style={{marginLeft: 3 }} title={
                                        <Typography>
                                            <Trans i18nKey="downloaded-transactions.All-reciepts/cash">
                                            All reciepts/cash inflows are shown as positive numbers and all disbursements/cash outflows are shown as negative numbers. <br /><br />Any foreign currency transaction in a foreign currency credit card is being converted to the local currency.
                                            </Trans>
                                        </Typography>
                                    }>
                                        <InfoIcon src='/static/linkFinancial/info-icon.svg' alt="" />
                                    </LightTooltip>
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>{t('downloaded-transactions.Reviewed')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <DividerStyle />
                    <TableBody>
                        {transactions.length === 0 ? (
                            <TableRow>
                                <TableCell colSpan={6} align="center">
                                    {t('downloaded-transactions.No-transaction')}
                                </TableCell>
                            </TableRow>
                        ) : (
                            stableSort(transactions, getComparator(order, orderBy)).map((transaction) => {
                                const {
                                    id,
                                    transactionDate,
                                    merchant,
                                    category_view: category,
                                    sub_category: subCategory,
                                    notes,
                                    amount,
                                    isReviewed,
                                    amountLocalCurrency,
                                } = transaction;
                                const categoryName =
                                    !subCategory?.name || category?.name === subCategory?.name
                                        ? category?.name
                                        : `${category?.name}: ${subCategory?.name}`;
                                const formattedDate = moment.parseZone(transactionDate).format('MMM DD, YYYY');
                                const showForeignCurrencyData = amount?.currency !== amountLocalCurrency?.currency;

                                let amountToShow = amountLocalCurrency;
                                if (amountToShow === null || !amountToShow?.amount === null) {
                                    amountToShow = amount;
                                }
                                let splitAmountText = '';
                                if (amountToShow?.isTransactionSplit) {
                                    splitAmountText = `${t('downloaded-transactions.Total-amount')} ${amountToShow?.originalAmount}`;
                                }
                                return (
                                    <TableRow
                                        key={id}
                                        onClick={() => onRowClick(id)}
                                        className={isReviewed ? 'transaction-reviewed' : ''}
                                    >
                                        <TableCell>{formattedDate}</TableCell>
                                        <TableCell>
                                            <div style={{
                                                maxWidth: '300px',
                                                minWidth: '150px',
                                                wordWrap: 'break-word'
                                            }}>
                                                {merchant?.name}
                                                {amountToShow?.isTransactionSplit ? (
                                                    <LightTooltip
                                                        title={
                                                            <p>{splitAmountText}</p>
                                                        }
                                                    >
                                                        <ShareOutlinedIcon style={{ height: 15 }} />
                                                    </LightTooltip>
                                                ) : (
                                                    ''
                                                )}
                                            </div>
                                        </TableCell>
                                        <TableCell>{categoryName}</TableCell>
                                        <TableCell>{notes}</TableCell>
                                        <TableCell>
                                            {showForeignCurrencyData && <span
                                                style={{
                                                    textAlign: 'center',
                                                    paddingTop: '6px',
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                }}
                                                className='foreign-currency-amount'
                                            >
                                                <span>{amount?.currency}</span>
                                                <span>
                                                    {Number(amount?.amount)?.toLocaleString('en-US', {
                                                        minimumFractionDigits: 2,
                                                    })}
                                                </span>
                                            </span>}
                                        </TableCell>
                                        <TableCell>
                                            <span
                                                style={{
                                                    textAlign: 'center',
                                                    paddingTop: '6px',
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    width: amountToShow?.isTransactionSplit ? '145px' : '120px',
                                                }}
                                            >
                                                <span>{currencySymbol}</span>
                                                <span>
                                                    {Number(amountToShow?.amount)?.toLocaleString('en-US', {
                                                        minimumFractionDigits: 2,
                                                    })}
                                                    {amountToShow?.isTransactionSplit ? (
                                                        <LightTooltip
                                                            title={<p>{splitAmountText}</p>}
                                                        >
                                                            <ShareOutlinedIcon style={{ height: 15 }} />
                                                        </LightTooltip>
                                                    ) : (
                                                        ''
                                                    )}
                                                </span>
                                            </span>{' '}
                                        </TableCell>
                                        <TableCell className="checkBox">
                                            <CheckboxStyle
                                                checked={isReviewed}
                                                onClick={(e) => handleIsReviewClicked(e, id, isReviewed)}
                                            />
                                        </TableCell>
                                    </TableRow>
                                );
                            })
                        )}
                        {}
                    </TableBody>
                </TableStyle>
            </TableContainer>
        </>
    );
}

import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { InfoIcon, LightTooltip } from 'theme/common-styles';
import { TableStyle, DiffTableContent, CenterTbText, BudgetInputTwo } from '../styled-components';

export default function HouseView({ currencySymbol }) {
    const { t } = useTranslation();

    const getBudgetInputTwo = (value, negative = false, sty = {}) => <BudgetInputTwo style={{ fontWeight: 600, paddingLeft: 0, ...sty }}>
        <span>{negative ? '-': ''}{currencySymbol}</span>
        <div>{value}</div>
    </BudgetInputTwo>

    return (
        <div className='divTableStyle'>
            <TableStyle style={{ padding: '0px', marginTop: '1%', borderSpacing: 20 }}>
                <tbody>
                    <tr>
                        <CenterTbText />
                        <CenterTbText />
                        <CenterTbText />
                        <CenterTbText />
                        <td colSpan={2} style={{ color: '#0F9672', fontWeight: '600', textAlign: 'right', paddingRight: '6%' }}>
                            {t('view-manual-transactions.estimated-value')}
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2} style={{ color: '#0F9672', fontWeight: '600', textAlign: 'left' }}>
                            {t('view-manual-transactions.description')}
                        </td>
                        <DiffTableContent style={{ textAlign: 'left' }}>{t('view-manual-transactions.date')}</DiffTableContent>
                        <DiffTableContent style={{ textAlign: 'right', minWidth: 150 }}>
                            {t('view-manual-transactions.major-additions')}
                            <LightTooltip style={{marginLeft: 3 }} title={
                                <Typography>{t('view-manual-transactions.major-additions-tooltip')}</Typography>
                            }>
                                <InfoIcon src='/static/linkFinancial/info-icon.svg' alt="" />
                            </LightTooltip>
                        </DiffTableContent>
                        <DiffTableContent style={{ textAlign: 'right' }}>{t('view-manual-transactions.increase')}</DiffTableContent>
                        <DiffTableContent style={{ color: 'red', textAlign: 'right' }}>{t('view-manual-transactions.decrease')}</DiffTableContent>
                        <DiffTableContent style={{ textAlign: 'right' }}>
                            {t('view-manual-transactions.balance')}
                            <LightTooltip style={{marginLeft: 3 }} title={
                                <Typography>{t('view-manual-transactions.balance-tooltip')}</Typography>
                            }>
                                <InfoIcon src='/static/linkFinancial/info-icon.svg' alt="" />
                            </LightTooltip>
                        </DiffTableContent>
                    </tr>
                    <tr>
                        <td colSpan={2} style={{ textAlign: 'left' }}>
                            {t('view-manual-transactions.original-purchase-price')}
                        </td>
                        <CenterTbText>21-Sep-21</CenterTbText>
                        <CenterTbText />
                        <CenterTbText />
                        <CenterTbText />
                        {getBudgetInputTwo('800,000')}
                    </tr>
                    <tr>
                        <td colSpan={2} style={{ textAlign: 'left' }}>
                            {t('view-manual-transactions.basement-renovation')}
                        </td>
                        <CenterTbText>15-Dec-22</CenterTbText>
                        {getBudgetInputTwo('40,000')}
                        <CenterTbText />
                        <CenterTbText />
                        {getBudgetInputTwo('840,000')}
                    </tr>
                    <tr>
                        <td colSpan={2} style={{ textAlign: 'left' }}>
                            {t('view-manual-transactions.value-increase')}
                        </td>
                        <CenterTbText>31-Jan-23</CenterTbText>
                        <CenterTbText />
                        {getBudgetInputTwo('60,000')}
                        <CenterTbText />
                        {getBudgetInputTwo('900,000')}
                    </tr>
                    <tr>
                        <td colSpan={2} style={{ textAlign: 'left' }}>
                            {t('view-manual-transactions.value-decrease')}
                        </td>
                        <CenterTbText>31-Dec-23</CenterTbText>
                        <CenterTbText />
                        <CenterTbText />
                        {getBudgetInputTwo('50,000', true, { color: 'red' })}
                        {getBudgetInputTwo('850,000')}
                    </tr>
                    <tr>
                        <CenterTbText />
                        <CenterTbText />
                        <CenterTbText />
                        {getBudgetInputTwo('40,000', false, { borderTop: 'solid 1px #0F9672', borderBottom: ' solid 3px #0F9672', paddingTop: 12, paddingBottom: 12 })}
                        {getBudgetInputTwo('60,000', false, { borderTop: 'solid 1px #0F9672', borderBottom: ' solid 3px #0F9672', paddingTop: 12, paddingBottom: 12 })}
                        {getBudgetInputTwo('50,000', true, { color: 'red', borderTop: 'solid 1px #0F9672', borderBottom: ' solid 3px #0F9672', paddingTop: 12, paddingBottom: 12 })}
                    </tr>
                    <tr/>
                    <tr>
                        <td colSpan={6} style={{ borderSpacing: 10 }}>
                            <CenterTbText style={{  fontWeight: 700,
                                fontSize: 18,
                                color: '#0F9672' }}>{t('view-manual-transactions.net-inc-dec-house')}</CenterTbText>
                            <CenterTbText />    
                        </td>
                        {getBudgetInputTwo('50,000', false, { borderTop: 'solid 1px #0F9672', borderBottom: ' solid 3px #0F9672', paddingBottom: 0, paddingTop: 0 })}
                    </tr>
                </tbody>
            </TableStyle>
        </div>
    );
}

import { AccordionDetails, AccordionSummary, Grid, Typography } from "@mui/material";
import Iconify from "components/Iconify";
import { Line } from "react-chartjs-2";
import { AccordionStyle } from "./styled-components";

export const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top'
        },
        title: {
            display: true,
            text: 'Chart.js Line Chart',
        },
    },
}

export default function GeographicChart({ geographicTrendData = {}, country = [] }) {
    const labels = Object.keys(geographicTrendData);
    const countryNames = country.map((c) => c.id);

    const dataValues = labels?.length ? Object.keys(geographicTrendData[labels[0]]) : [];
    const data = {
        labels,
        datasets: countryNames?.map((d) => {
            const colorCode = `#${Math.floor(Math.random()*16777215).toString(16)}`;
            return {
                label: d,
                hidden: !countryNames.includes(d),
                data: labels.map((l) => geographicTrendData[l][d]),
                fill: false,
                borderColor: colorCode,
                backgroundColor: colorCode,
            }
        })
    };
    return (
        <Grid
            container
            spacing={4}
            marginTop={2}
            className="marginSmTop"
            justifyContent="center"
            alignItems="flex-start"
            paddingLeft={4}
        >

            <AccordionStyle>
                <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
                    <Typography>Geographic Trend Report</Typography>
                    <Iconify icon="ant-design:plus-circle-outlined" sx={{ minWidth: 22, height: 22 }} />
                    <Iconify icon="akar-icons:circle-minus" sx={{ minWidth: 22, height: 22 }} />
                </AccordionSummary>
                <AccordionDetails>
                    <Line data={data} options={options} />
                </AccordionDetails>
            </AccordionStyle>
        </Grid>
        
    );
}

import { useTranslation } from 'react-i18next';
import { TableStyle, DiffTableContent, CenterTbText, BudgetInputTwo } from '../styled-components';

export default function InvestmentView({ currencySymbol }) {
    const { t } = useTranslation();

    const getBudgetInputTwo = (value, negative = false, sty = {}) => <BudgetInputTwo style={{ fontWeight: 600, paddingLeft: 0, ...sty }}>
        <span>{negative ? '-': ''}{currencySymbol}</span>
        <div>{value}</div>
    </BudgetInputTwo>

    return (
        <div className='divTableStyle'>
            <TableStyle style={{ padding: '0px 2%', marginTop: '1%', borderSpacing: 15 }}>
                <tbody>
                    <tr>
                        <CenterTbText />
                        <CenterTbText />
                        <CenterTbText />
                        <CenterTbText />
                        <td colSpan={2} style={{ color: '#0F9672', fontWeight: '600', textAlign: 'center' }}>
                            {t('view-manual-transactions.value')}
                        </td>
                    </tr>
                    <tr>
                        <DiffTableContent style={{ textAlign: 'left' }}>{t('view-manual-transactions.description')}</DiffTableContent>
                        <DiffTableContent style={{ textAlign: 'left' }}>{t('view-manual-transactions.date')}</DiffTableContent>
                        <DiffTableContent style={{ textAlign: 'right' }}>{t('view-manual-transactions.contributions')}</DiffTableContent>
                        <DiffTableContent style={{ color: 'red', textAlign: 'right' }}>{t('view-manual-transactions.withdrawls')}</DiffTableContent>
                        <DiffTableContent style={{ textAlign: 'right' }}>{t('view-manual-transactions.increase')}</DiffTableContent>
                        <DiffTableContent style={{ color: 'red', textAlign: 'right' }}>{t('view-manual-transactions.decrease')}</DiffTableContent>
                        <DiffTableContent style={{ textAlign: 'right' }}>{t('view-manual-transactions.balance')}</DiffTableContent>
                    </tr>
                    <tr>
                        <CenterTbText>{t('view-manual-transactions.opening-balance')}</CenterTbText>
                        <CenterTbText>21-Sep-21</CenterTbText>
                        <CenterTbText />
                        <CenterTbText />
                        <CenterTbText />
                        <CenterTbText />
                        {getBudgetInputTwo('550,000')}
                    </tr>
                    <tr>
                        <CenterTbText>{t('view-manual-transactions.contribution')}</CenterTbText>
                        <CenterTbText>15-Dec-22</CenterTbText>
                        {getBudgetInputTwo('50,000')}
                        <CenterTbText />
                        <CenterTbText />
                        <CenterTbText />
                        {getBudgetInputTwo('600,000')}
                    </tr>
                    <tr>
                        <CenterTbText>{t('view-manual-transactions.withdrawl')}</CenterTbText>
                        <CenterTbText>31-Jan-23</CenterTbText>
                        <CenterTbText />
                        {getBudgetInputTwo('20,000', true, { color: 'red' })}
                        <CenterTbText />
                        <CenterTbText />
                        {getBudgetInputTwo('580,000')}
                    </tr>
                    <tr>
                        <CenterTbText>{t('view-manual-transactions.value-change')}</CenterTbText>
                        <CenterTbText>28-Feb-23</CenterTbText>
                        <CenterTbText />
                        <CenterTbText />
                        {getBudgetInputTwo('120,000')}
                        <CenterTbText />
                        {getBudgetInputTwo('700,000')}
                    </tr>
                    <tr>
                        <CenterTbText>{t('view-manual-transactions.value-change')}</CenterTbText>
                        <CenterTbText>31-Mar-23</CenterTbText>
                        <CenterTbText />
                        <CenterTbText />
                        <CenterTbText />
                        {getBudgetInputTwo('20,000', true, { color: 'red' })}
                        {getBudgetInputTwo('680,000')}
                    </tr>
                    <tr>
                        <CenterTbText />
                        <CenterTbText />
                        {getBudgetInputTwo('50,000', false, { borderTop: 'solid 1px #0F9672', borderBottom: ' solid 3px #0F9672', paddingTop: 12, paddingBottom: 12 })}
                        {getBudgetInputTwo('20,000', true, { color: 'red', borderTop: 'solid 1px #0F9672', borderBottom: ' solid 3px #0F9672', paddingTop: 12, paddingBottom: 12 })}
                        {getBudgetInputTwo('120,000', false, { borderTop: 'solid 1px #0F9672', borderBottom: ' solid 3px #0F9672', paddingTop: 12, paddingBottom: 12 })}
                        {getBudgetInputTwo('20,000', true, { color: 'red', borderTop: 'solid 1px #0F9672', borderBottom: ' solid 3px #0F9672', paddingTop: 12, paddingBottom: 12 })}
                    </tr>
                    <tr/>
                    <tr>
                        <td colSpan={6} style={{ borderSpacing: 10 }}>
                            <CenterTbText style={{  fontWeight: 700,
                                fontSize: 18,
                                color: '#0F9672' }}>{t('view-manual-transactions.net-inc-dec')}</CenterTbText>
                            <CenterTbText />    
                        </td>
                        {getBudgetInputTwo('130,000', false, { borderTop: 'solid 1px #0F9672', borderBottom: ' solid 3px #0F9672', paddingBottom: 0, paddingTop: 0 })}
                    </tr>
                </tbody>
            </TableStyle>
        </div>
    );
}
